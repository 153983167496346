var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/ButtonIcon/ButtonIcon.jinja"] = require( "front/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["front/components/SectionBadge/SectionBadge.jinja"] = require( "front/components/SectionBadge/SectionBadge.jinja" );
dependencies["front/components/ViewCount/ViewCount.jinja"] = require( "front/components/ViewCount/ViewCount.jinja" );
dependencies["front/components/PostItem/PostItem.jinja"] = require( "front/components/PostItem/PostItem.jinja" );
dependencies["front/components/Subscribe/Subscribe.jinja"] = require( "front/components/Subscribe/Subscribe.jinja" );
dependencies["front/components/StyleTag/StyleTag.jinja"] = require( "front/components/StyleTag/StyleTag.jinja" );
dependencies["front/components/Share/Share.jinja"] = require( "front/components/Share/Share.jinja" );
dependencies["front/components/SubscribeSections/SubscribeSections.jinja"] = require( "front/components/SubscribeSections/SubscribeSections.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Post/Post.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/Post/Post.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("front/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "ButtonIcon")) {
var t_11 = t_8.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_11);
output += "\n";
env.getTemplate("front/components/SectionBadge/SectionBadge.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "SectionBadge")) {
var t_15 = t_12.SectionBadge;
} else {
cb(new Error("cannot import 'SectionBadge'")); return;
}
context.setVariable("SectionBadge", t_15);
output += "\n";
env.getTemplate("front/components/ViewCount/ViewCount.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "ViewCount")) {
var t_19 = t_16.ViewCount;
} else {
cb(new Error("cannot import 'ViewCount'")); return;
}
context.setVariable("ViewCount", t_19);
output += "\n";
env.getTemplate("front/components/PostItem/PostItem.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "PostItem")) {
var t_23 = t_20.PostItem;
} else {
cb(new Error("cannot import 'PostItem'")); return;
}
context.setVariable("PostItem", t_23);
output += "\n";
env.getTemplate("front/components/Subscribe/Subscribe.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "Subscribe")) {
var t_27 = t_24.Subscribe;
} else {
cb(new Error("cannot import 'Subscribe'")); return;
}
context.setVariable("Subscribe", t_27);
output += "\n";
env.getTemplate("front/components/StyleTag/StyleTag.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "StyleTag")) {
var t_31 = t_28.StyleTag;
} else {
cb(new Error("cannot import 'StyleTag'")); return;
}
context.setVariable("StyleTag", t_31);
output += "\n";
env.getTemplate("front/components/Share/Share.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
t_32.getExported(function(t_34,t_32) {
if(t_34) { cb(t_34); return; }
if(Object.prototype.hasOwnProperty.call(t_32, "Share")) {
var t_35 = t_32.Share;
} else {
cb(new Error("cannot import 'Share'")); return;
}
context.setVariable("Share", t_35);
output += "\n";
env.getTemplate("front/components/SubscribeSections/SubscribeSections.jinja", false, "assets/app/front/pages/Post/Post.jinja", false, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
t_36.getExported(function(t_38,t_36) {
if(t_38) { cb(t_38); return; }
if(Object.prototype.hasOwnProperty.call(t_36, "SubscribeSections")) {
var t_39 = t_36.SubscribeSections;
} else {
cb(new Error("cannot import 'SubscribeSections'")); return;
}
context.setVariable("SubscribeSections", t_39);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_43,t_42) {
if(t_43) { cb(t_43); return; }
output += t_42;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_45,t_44) {
if(t_45) { cb(t_45); return; }
output += t_44;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_47,t_46) {
if(t_47) { cb(t_47); return; }
output += t_46;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_49,t_48) {
if(t_49) { cb(t_49); return; }
output += t_48;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popup"))(env, context, frame, runtime, function(t_51,t_50) {
if(t_51) { cb(t_51); return; }
output += t_50;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_53,t_52) {
if(t_53) { cb(t_53); return; }
output += t_52;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 12, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 13, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n        <meta property=\"og:type\" content=\"website\"/>\n        <meta property=\"og:image\" content=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"img")),"share"), env.opts.autoescape);
output += "\"/>\n        <meta property=\"og:image:width\" content=\"1200\"/>\n        <meta property=\"og:image:height\" content=\"630\"/>\n        <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 20, colno = 70, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n        <meta property=\"og:title\" content=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
output += "\"/>\n";
output += "\n    ";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 25;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 26, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--posts","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 29;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "PostPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popup(env, context, frame, runtime, cb) {
var lineno = 31;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Post ";
if(!runtime.contextOrFrameLookup(context, frame, "server")) {
output += "Post--toAnimate";
;
}
output += "\">\n        <div class=\"Post-floatNav\"></div>\n        <div class=\"Post-close\">";
output += runtime.suppressValue((lineno = 34, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, [])), env.opts.autoescape);
output += "</div>\n\n        <div class=\"Post-header\">\n            <div class=\"Post-img Post-img--align-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"imgAlign"), env.opts.autoescape);
output += "\">\n                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"img")),"w1024"), env.opts.autoescape);
output += "\"\n                    srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"img")),"w1440"), env.opts.autoescape);
output += " 1440w,\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"img")),"w1920"), env.opts.autoescape);
output += " 1920w\"\n                    alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
output += "\">\n            </div>\n\n            <div class=\"Post-shareButton\">\n                ";
output += runtime.suppressValue((lineno = 47, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Share"), "Share", context, [runtime.makeKeywordArgs({"classes": "Share--post"})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <div class=\"Post-body\">\n            <div class=\"Post-content\">\n                <div class=\"Post-meta\">\n                    <div class=\"Post-badge\">\n                        ";
output += runtime.suppressValue((lineno = 55, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SectionBadge"), "SectionBadge", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"section")])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"Post-date\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"dateText"), env.opts.autoescape);
output += "</div>\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"externalLink")) {
output += "<a class=\"Post-externalLink\"\n                        href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"externalLink"), env.opts.autoescape);
output += "\"\n                        target=\"_blank\">";
output += runtime.suppressValue((lineno = 61, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["link"])), env.opts.autoescape);
output += "<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"externalLinkTitle"), env.opts.autoescape);
output += "</span></a>\n                    ";
;
}
output += "</div>\n\n                <h1 class=\"Post-title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
output += "</h1>\n\n                <div class=\"Post-text\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"text")), env.opts.autoescape);
output += "</div>\n\n                <div class=\"Post-bodyFooter\">\n                    <div class=\"Post-tags\">\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"tags") && env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"tags")) > 0) {
frame = frame.push();
var t_56 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"tags");
if(t_56) {t_56 = runtime.fromIterator(t_56);
var t_55 = t_56.length;
for(var t_54=0; t_54 < t_56.length; t_54++) {
var t_57 = t_56[t_54];
frame.set("t", t_57);
frame.set("loop.index", t_54 + 1);
frame.set("loop.index0", t_54);
frame.set("loop.revindex", t_55 - t_54);
frame.set("loop.revindex0", t_55 - t_54 - 1);
frame.set("loop.first", t_54 === 0);
frame.set("loop.last", t_54 === t_55 - 1);
frame.set("loop.length", t_55);
output += "\n                            ";
output += runtime.suppressValue((lineno = 73, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "StyleTag"), "StyleTag", context, [t_57,runtime.makeKeywordArgs({"tag": "a","link": (lineno = 73, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:index"])) + "?tags=" + env.getFilter("string").call(context, runtime.memberLookup((t_57),"id"))})])), env.opts.autoescape);
output += "\n                        ";
;
}
}
frame = frame.pop();
;
}
output += "\n                        <div class=\"Post-viewCount\">";
output += runtime.suppressValue((lineno = 76, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ViewCount"), "ViewCount", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"viewCount"),runtime.makeKeywordArgs({"classes": "ViewCount--post"})])), env.opts.autoescape);
output += "</div>\n                    </div>\n                    <div class=\"Post-shares\">\n                        <div class=\"Post-share Post-share--tg\">\n                            <div class=\"Post-shareIcon\">";
output += runtime.suppressValue((lineno = 80, colno = 68, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["telegramIcon"])), env.opts.autoescape);
output += "</div>\n                            <div class=\"Post-shareText\">";
output += runtime.suppressValue((lineno = 81, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Отправить в Телеграм"])), env.opts.autoescape);
output += "</div>\n                        </div>\n                        <div class=\"Post-share Post-share--vk\">\n                            <div class=\"Post-shareIcon\">";
output += runtime.suppressValue((lineno = 84, colno = 68, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["vkIcon"])), env.opts.autoescape);
output += "</div>\n                            <div class=\"Post-shareText\">";
output += runtime.suppressValue((lineno = 85, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Поделиться в ВКонтакте"])), env.opts.autoescape);
output += "</div>\n                        </div>\n                        ";
output += "\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"Post-footer\">\n            <div class=\"Post-similar\">\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"similar") && env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"similar")) > 0) {
output += "<header class=\"Post-similarHeader\">";
output += runtime.suppressValue((lineno = 105, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Похожие материалы"])), env.opts.autoescape);
output += "</header>\n\n                <div class=\"Post-similarList\">\n                    ";
frame = frame.push();
var t_60 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"similar");
if(t_60) {t_60 = runtime.fromIterator(t_60);
var t_59 = t_60.length;
for(var t_58=0; t_58 < t_60.length; t_58++) {
var t_61 = t_60[t_58];
frame.set("p", t_61);
frame.set("loop.index", t_58 + 1);
frame.set("loop.index0", t_58);
frame.set("loop.revindex", t_59 - t_58);
frame.set("loop.revindex0", t_59 - t_58 - 1);
frame.set("loop.first", t_58 === 0);
frame.set("loop.last", t_58 === t_59 - 1);
frame.set("loop.length", t_59);
output += "\n                        ";
output += runtime.suppressValue((lineno = 109, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "PostItem"), "PostItem", context, [runtime.makeKeywordArgs({"post": t_61,"classes": "PostItem--post"})])), env.opts.autoescape);
output += "\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>";
;
}
output += "\n\n";
output += "\n";
output += "\n";
output += "\n        </div>\n        <div class=\"Post-subscribe\">\n            ";
output += runtime.suppressValue((lineno = 120, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Subscribe"), "Subscribe", context, [runtime.makeKeywordArgs({"classes": "Subscribe--post"})])), env.opts.autoescape);
output += "\n        </div>\n\n        <img src=\"/p/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"id"), env.opts.autoescape);
output += ".gif\" alt=\"util\">\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 127;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_62,hole_105) {
if(t_62) { cb(t_62); return; }
hole_105 = runtime.markSafe(hole_105);
output += "\n    ";
output += runtime.suppressValue(hole_105, env.opts.autoescape);
output += "\n\n    <script>\n        app.data.tags = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "tags"))), env.opts.autoescape);
output += ";\n        app.data.activeTags = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "activeTags"))), env.opts.autoescape);
output += ";\n        app.data.activeSection = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "section"))), env.opts.autoescape);
output += ";\n        app.data.sections = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "sections"))), env.opts.autoescape);
output += ";\n        app.data.hasMore = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "hasMore"))), env.opts.autoescape);
output += ";\n        app.data.posts = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "posts"))), env.opts.autoescape);
output += ";\n        app.data.favorite = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "favorite"))), env.opts.autoescape);
output += ";\n        app.data.news = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "news"))), env.opts.autoescape);
output += ";\n\n        app.data.post = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "post"))), env.opts.autoescape);
output += ";\n    </script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_social: b_social,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_popup: b_popup,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Post/Post.jinja"] , dependencies)