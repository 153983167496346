var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Input/Input.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Input/Input.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += " \n\n";
var macro_t_5 = runtime.makeMacro(
["class", "name", "value", "label", "placeholder", "message", "icon"], 
[], 
function (l_class, l_name, l_value, l_label, l_placeholder, l_message, l_icon, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("class", l_class);
frame.set("name", l_name);
frame.set("value", l_value);
frame.set("label", l_label);
frame.set("placeholder", l_placeholder);
frame.set("message", l_message);
frame.set("icon", l_icon);
var t_6 = "";t_6 += "\n    <div class=\"Input ";
t_6 += runtime.suppressValue(l_class, env.opts.autoescape);
t_6 += "\" data-id='";
t_6 += runtime.suppressValue(l_name, env.opts.autoescape);
t_6 += "'>\n        ";
if(l_label) {
t_6 += "\n            <label for=\"";
t_6 += runtime.suppressValue(l_name, env.opts.autoescape);
t_6 += "\">";
t_6 += runtime.suppressValue(l_label, env.opts.autoescape);
t_6 += "</label>\n        ";
;
}
t_6 += " \n        ";
if(l_message) {
t_6 += "\n            <p>";
t_6 += runtime.suppressValue(l_message, env.opts.autoescape);
t_6 += "</p>\n        ";
;
}
t_6 += "\n        ";
if(l_icon) {
t_6 += "\n            ";
t_6 += runtime.suppressValue((lineno = 11, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, [l_icon])), env.opts.autoescape);
t_6 += "\n        ";
;
}
t_6 += "\n        <input type=\"text\" id=\"";
t_6 += runtime.suppressValue(l_name, env.opts.autoescape);
t_6 += "\" name='";
t_6 += runtime.suppressValue(l_name, env.opts.autoescape);
t_6 += "' title='";
t_6 += runtime.suppressValue(l_label, env.opts.autoescape);
t_6 += "' value='";
t_6 += runtime.suppressValue(l_value, env.opts.autoescape);
t_6 += "' placeholder=\"";
t_6 += runtime.suppressValue(l_placeholder, env.opts.autoescape);
t_6 += "\" autocomplete=\"";
t_6 += runtime.suppressValue(l_name, env.opts.autoescape);
t_6 += "\">\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("Input");
context.setVariable("Input", macro_t_5);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Input/Input.jinja"] , dependencies)